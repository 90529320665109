






































import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue';
import { Component, Vue, Ref, Mixins } from 'vue-property-decorator';
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalChat from '@/components/modules/drillsv3/organisms/ModalChat.vue'
import ModalChatMath from '@/components/modules/drillsv3/organisms/ModalChatMath.vue'
import QuestionImageHorizontalV3 from '@/components/modules/drillsv3/organisms/QuestionImageHorizontalV3.vue'
import StudentBottomPanel from '@/components/organisms/v2/StudentBottomPanel.vue'

interface BookmarkDetailResponse {
    curriculumSUnitId: number;
    questionCode: string;
    questionImage: {
        d: string[];
        m: string[];
        j: string[];
    },
    classModeId: number;
    classCategoryId: number;
    title: string;
    subjectCode: string;
    classMode: string;
    resultDrillId: number;
    questionMemo?: string
}
@Component({
    components: {
        SidebarSwitcher,
        QuestionImageHorizontalV3,
        ButtonBase,
        ModalChat,
        StudentBottomPanel,
        ModalChatMath
    }
})
export default class BookmarkDetail extends Vue {
    @Ref() modalChat!: ModalChat

    @Ref() modalChatMathRef!: ModalChatMath

    private currentQuestion: {
        title: string,
        questionCode: string,
        url: string[],
        urlD: string[],
        sijiUrl: string,
        classModeId: number,
        classCategoryId: number,
        questionTitle: string,
        classMode: string,
        subjectCode: string,
        resultDrillId?: number;
    } = {
            title: '',
            questionCode: this.$route.params.questionCode,
            url: [],
            urlD: [],
            sijiUrl: '',
            classModeId: 0,
            classCategoryId: 0,
            questionTitle: '',
            classMode: '',
            subjectCode: '',
        }

    private handleFormatDetailBookmark(data?: BookmarkDetailResponse) {
        if (!data) return undefined
        return {
            title: '',
            questionCode: this.$route.params.questionCode,
            url: data.questionImage.m,
            urlD: data.questionImage.d,
            sijiUrl: data.questionImage.j?.[0] || '',
            classModeId: data.classModeId,
            classCategoryId: data.classCategoryId,
            questionTitle: data.title,
            classMode: data.classMode,
            subjectCode: data.subjectCode,
            resultDrillId: data?.resultDrillId,
            questionMemo: data?.questionMemo
        }
    }

    private async handleGetDetailBookmark() {
        Vue.prototype.$loading.start()
        const result = await Vue.prototype.$http.httpWithToken.get(`/v3/history/question_bookmarks/bookmark_details`, { params: { question_code: this.$route.params.questionCode, curriculum_s_unit_id: this.$route.params.curriculumSUnitId } });
        Vue.prototype.$loading.complete()
        this.currentQuestion = this.handleFormatDetailBookmark(result?.data) || this.currentQuestion
    }

    private get getTitlePage() {
        return this.currentQuestion?.questionTitle || ''
    }

    private get getCurriculumSUnitId() {
        return this.$route.params.curriculumSUnitId
    }

    private async handleDeleteBookmark() {
        const statusConfirm = confirm('この項目を削除してもよろしいですか?')
        if (statusConfirm) {
            await Vue.prototype.$http.httpWithToken.post(`/v3/history/question_bookmarks`, { question_code: this.currentQuestion.questionCode, curriculum_s_unit_id: this.$route.params.curriculumSUnitId, result_drill_id: this.currentQuestion.resultDrillId })
            this.$router.push(`/student/v3/bookmark/${this.currentQuestion.classMode}/${this.currentQuestion.subjectCode}`)
            return;
        }
    }

    private handleBackToPreviousPage() {
        this.$router.push(`/student/v3/bookmark/${this.currentQuestion.classMode}/${this.currentQuestion.subjectCode}`)
    }

    private async handleRetryOrSimilar(type: 'retry' | 'similar') {
        try {
            Vue.prototype.$loading.start()
            const startRetry = await Vue.prototype.$http.httpWithToken.post(type === 'similar' ? '/v3/similar_questions/drills/start' : '/v3/question_bookmarks/drills/start', {
                question_code: this.currentQuestion.questionCode,
                curriculum_s_unit_id: this.$route.params.curriculumSUnitId,
                study_type: type,
                class_category_id: this.currentQuestion.classCategoryId,
                class_mode_id: this.currentQuestion.classModeId,
                study_material: 'bookmark'
            })
            Vue.prototype.$loading.complete()
            const resultDrillId = startRetry?.data?.resultDrillId
            if (resultDrillId) {
                if (this.currentQuestion.subjectCode === 'su') {
                    this.$router.push(`/student/v3/${type === 'similar' ? 'drill-similar-math' : 'drill-one-math'}/${this.currentQuestion.questionCode}?resultDrillId=${resultDrillId}&page=${startRetry?.data.currentPage}&curriculumSUnitId=${this.$route.params.curriculumSUnitId}`);
                } else {
                    this.$router.push(`/student/v3/${type === 'similar' ? 'drill-similar' : 'drill-one'}/${this.currentQuestion.questionCode}?resultDrillId=${resultDrillId}&page=${startRetry?.data.currentPage}&curriculumSUnitId=${this.$route.params.curriculumSUnitId}`);
                }

            }
        } catch (error) {
            const status = (error as any).response?.data?.status
            if (status === 422 || status === 400) {
                alert('類題の生成に失敗しました。しばらくしてから再試行してください。')
                return;
            }
        }

    }

    private mounted() {
        this.handleGetDetailBookmark()
    }
}
